:root {
  background-color: #FAF9F9;
  text-align: center;
  max-width: 100vw;

  --font-family-primary: "Roboto", sans-serif;
  --font-family-primary-sc: 'Alegreya Sans SC', sans-serif;
  --font-family-secondary: 'Allura', cursive;
  --font-family-code: 'EightBitDragon', sans-serif;

  --color-bg: #FAF9F9;
  --color-dark-blue: #382E75;
  --color-mint: #e3eff2cc;
  --color-dark-mint: #75b0bf;

  --white-transparent: rgba(255, 255, 255, 0.85);
  --white-transparent-95: rgba(255, 255, 255, 0.95);
  --radius: 0.2rem;
}

.container {
  text-align: center;
  min-height: 100vh;
  background-image: url("./assets/test.png");
  background-size: cover;
  background-color: white;
  padding: 5vw;
}

.inner-container {
  background-color: mintcream;
  border: 1px solid midnightblue;
}

.cursive {
  font-family: var(--font-family-secondary) !important;
}

.sc {
  font-family: var(--font-family-primary-sc) !important;
}

.title {
  display: initial;
  background-image: url("./assets/dot.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.title.dot-right {
  background-position: top right;
  padding-right: 1rem;
}

.title.dot-left {
  background-position: top left;
  padding-left: 1.5rem;
  padding-top: 0.5rem;
}

@media screen and (min-width: 900px) {
  .title-container {
    margin-top: 100px !important;
  }
}